






































































































import {Component, Prop, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Review} from '~/components/data-class/data-class'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import moment from 'moment'
import {Dict, opDefaultArr, opDefaultObj} from '~/utils/misc'
import copy from 'copy-to-clipboard'

@Component({
    components: {}
})
export default class CourseReviews extends mixins(QueryParserMixin, PaginationMixin) {
    reviews: Review[] = []

    member = ''
    course = ''
    comment = ''
    hidden: boolean | '' = ''
    loading = false

    queryDef = [
        {localVar: 'member', queryStr: 'rmember'},
        {localVar: 'course'},
        {localVar: 'comment'},
        {localVar: 'hidden', type: QueryType.Boolean},
        {localVar: 'currentPage', queryStr: 'rpage', type: QueryType.Number}
    ]

    step = 20
    itemUnit = 'reviews'

    @Prop(opDefaultArr)
    blacklist: string[]
    @Prop(opDefaultObj)
    bNames: Dict<string>

    moment = moment

    created() {
        this.parseQuery()
        this.getReviews()
    }

    reason = ''
    mounted() {
        window['reason'] = async (reason) => {
            this.reason = reason
            return this.getReviews()
        }
        window['rr'] = async () => {
            this.reason = 'Repeat'
            return this.getReviews()
        }
    }

    async getReviews() {
        const param: { [key: string]: string } = {
            skip: (this.currentPage - 1) * this.step + '',
            limit: this.step + ''
        }

        if (this.hidden !== '') param.hidden = this.hidden + ''
        if (this.member) param.member_id = this.member
        if (this.course) param.course_id = this.course
        if (this.comment) param.comment = this.comment
        if (this.reason) param.reason = this.reason
        this.loading = true

        try {
            const res = await createRequest('/courses/reviews', 'get', param).send()
            this.reviews = res.data.reviews
            this.totalCount = res.data.count
            this.itemsLength = this.reviews.length
            this.setQuery()
        } catch (e) {
            console.error(e)
            this.$message.error('Error')
        }
        this.loading = false
    }

    get filter() {
        return `${this.member}${this.course}${this.comment}${this.hidden}`
    }

    @Watch('filter')
    filterChanged() {
        this.toPage(1)
    }

    // Page
    async toPage(pageNum) {
        this.currentPage = pageNum
        this.getReviews()
    }

    searchMember(memberId) {
        this.member = (memberId === this.member) ? '' : memberId
        this.toPage(1)
    }

    searchCourse(courseId) {
        this.course = (courseId === this.course) ? '' : courseId
        this.toPage(1)
    }

    tableRowClassName({row}) {
        return row.hide ? 'hidden-comment' : ''
    }

    async hideReview(review: Review, hide: boolean) {
        await createRequest(`/review/${review._id}/hide`, 'patch', {}, {hide}).send()
        review.hide = hide
    }

    async addBlacklist(review: Review) {
        await createRequest('/courses/reviews/blacklist', 'post', {}, {
            member_id: review.member_id,
            ban: true
        }).send()
        this.blacklist.push(review.member_id)
        this.bNames[review.member_id] = review.display_name!
    }

    encodeComment(cm: string) {
        return cm.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }
}
