






















import {Component, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import QueryParserMixin from '~/mixins/query-parser-mixin'
import Review from './reviews.vue'
import Blacklist from './blacklist.vue'
import {Dict} from '~/utils/misc'
import {createRequest} from '~/utils/network-request'
import {CoursePermission} from '~/utils/permissions'

enum Type {
    review = 'review',
    blacklist = 'blacklist'
}

@Component({
    components: {
        Review,
        Blacklist
    },
    metaInfo() {
        return {
            title: 'Course Reviews'
        }
    }
})
export default class ReviewsManager extends mixins(QueryParserMixin) {
    static permission = CoursePermission.CourseReviews
    Type = Type

    tab: string = Type.review

    blacklist: string[] = []
    bNames: Dict<string> = {}

    created() {
        console.log('完全超出預期') // for copy
        this.getBlacklist()
    }

    async getBlacklist() {
        const res = await createRequest('/courses/reviews/blacklist', 'get').send()
        this.blacklist = res.data.blacklist
        this.bNames = res.data.display_names
    }

    get isReview() {
        return this.tab === Type.review
    }

    get isBlacklist() {
        return this.tab === Type.blacklist
    }
}
