







































import {Component, Prop, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Review} from '~/components/data-class/data-class'
import {CoursePermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import moment from 'moment'
import {Dict, opDefaultArr, opDefaultObj} from '~/utils/misc'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Review Blacklist'
        }
    }
})
export default class CourseReviews extends mixins(QueryParserMixin, PaginationMixin) {
    reviews: Review[] = []

    member = ''

    queryDef = [
        {localVar: 'member', queryStr: 'bmember'},
    ]

    step = 20
    itemUnit = 'members'

    @Prop(opDefaultArr)
    blacklist: string[]
    @Prop(opDefaultObj)
    bNames: Dict<string>

    moment = moment

    created() {
        this.totalCount = this.blacklist.length
    }

    get filteredList() {
        const list = this.member ? this.blacklist.filter(mid => mid.includes(this.member.toUpperCase())) : this.blacklist
        this.totalCount = list.length
        return list
    }

    get currentList() {
        const list = this.filteredList.slice(this.skip, this.skip + this.step)
        this.itemsLength = list.length
        return list
    }

    // Page
    async toPage(pageNum) {
        this.currentPage = pageNum
    }

    async removeBlacklist(memberId: string) {
        await createRequest('/courses/reviews/blacklist', 'post', {}, {
            member_id: memberId,
            ban: false
        }).send()
        const index = this.blacklist.indexOf(memberId)
        if (index > -1)
            this.blacklist.splice(index, 1)
    }
}
